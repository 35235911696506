<template>
  <div>
    <a-modal
      v-model="isShowFirstStepModal"
      centered
      :width="800"
      :mask-closable="false"
      ok-text="下一步"
      :ok-button-props="{ props: { loading: firstStepSubmitting } }"
      title="划拨"
      @ok="handleValidate"
    >
      <a-row>
        <a-form
          class="iotplt-compact-form"
          :form="firstStepForm"
          @submit="handleValidate"
        >
          <a-col :span="24">
            <a-form-item
              :wrapper-col="{ span: 24 }"
            >
              <common-iccid-input
                :rows="15"
                ref="iccidInput"
                v-decorator="['iccids', {
                  initialValue: { data: this.iccids },
                  rules: [{ validator: checkIccids }],
                }]"
              />
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
    </a-modal>

    <a-modal
      v-model="isShowSubmitModal"
      centered
      :width="600"
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="划拨"
      @ok="handleSubmit"
    >
      <a-row>
        <a-form
          class="iotplt-compact-form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          :form="submitForm"
          @submit="handleSubmit"
        >
          <a-form-item label="已选择卡数">
            <a-input
              disabled
              v-model="this.validIccids.length"
            />
          </a-form-item>

          <a-form-item label="选择客户">
            <a-select
              show-search
              allow-clear
              :filter-option="filterOption"
              :loading="loadingAgencyOptions"
              v-decorator="['allocate_to_agent_id', {
                rules: [
                  { required: true, message: '请选择客户' }
                ]
              }]"
            >
              <a-select-option
                v-for="agent in agencyOptions"
                :key="agent.id"
                :value="agent.id"
              >
                {{ agent.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            label="备注"
            extra="*划拨将产生划拨账单，账单金额需要根据每张卡上套餐的客户价格与卡板的客户价格进行计算"
          >
            <a-textarea
              v-decorator="['remark', {
                rules: [
                  { max: 200, message: '最多200个字符' },
                ]
              }]"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-item>
        </a-form>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { allocateSimCardsToAgency, validateAllocateSimCardsToAgency } from '@/api/batch_operation'
import CommonIccidInput from '@/components/IccidInput/Common'
import { findEffectiveAgencyOptions } from '@/api/agent'

export default {
  name: 'BatchOperationAllocateSimCardsToAgency',
  components: { CommonIccidInput },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    iccids: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    const isShowFirstStepModal = this.visible
    return {
      loadingAgencyOptions: false,
      agencyOptions: [],
      firstStepSubmitting: false,
      submitting: false,
      validIccids: [],
      isShowFirstStepModal: isShowFirstStepModal,
      isShowSubmitModal: false,
      firstStepForm: this.$form.createForm(this, { name: 'allocate_sim_cards_to_agency_first_step' }),
      submitForm: this.$form.createForm(this, { name: 'allocate_sim_cards_to_agency_submit' })
    }
  },
  watch: {
    // 步骤中任何一个弹窗打开，则 isShow 应为 true
    isShowFirstStepModal(val) {
      this.isShow = val || this.isShowSubmitModal
    },

    isShowSubmitModal(val) {
      this.isShow = val || this.isShowFirstStepModal
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    topAgentId() {
      return this.$store.getters.userAgentId
    }
  },
  created() {
    this.fetchAgencyOptions()
  },
  methods: {
    fetchAgencyOptions() {
      this.loadingAgencyOptions = true
      findEffectiveAgencyOptions({ top_agent_id: this.topAgentId }).then((res) => {
        if (res.code === 0) {
          this.agencyOptions = res.data
        }
        this.loadingAgencyOptions = false
      })
    },

    checkIccids(rule, value, callback) {
      if (!value || !value.inputValue) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入ICCID')
        return
      }

      if (value.validateStatus === 'error') {
        callback(value.errorMsg)
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    handleValidate(e) {
      e.preventDefault()
      if (this.firstStepSubmitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.firstStepForm.validateFields((err, values) => {
        if (!err) {
          this.firstStepSubmitting = true
          validateAllocateSimCardsToAgency({
            iccids: values.iccids.data,
            top_agent_id: this.topAgentId
          }).then((res) => {
            if (res.code === 0) {
              if (res.data.is_valid) {
                // 关闭模态框
                this.isShowSubmitModal = true
                this.validIccids = values.iccids.data
                this.isShowFirstStepModal = false
              } else {
                this.firstStepForm.setFieldsValue({
                  iccids: { inputValue: res.data.result }
                })
                this.$nextTick(() => {
                  this.$refs.iccidInput.handleIccids()
                })
              }
            }
            this.firstStepSubmitting = false
          })
        }
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.submitForm.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          allocateSimCardsToAgency({
            ...values,
            top_agent_id: this.topAgentId,
            iccids: this.validIccids
          }).then((res) => {
            if (res.code === 0) {
              this.isShowSubmitModal = false
              // 告知父组件已完成
              this.$emit('completed')
              this.$success({
                title: '提交成功',
                content: (
                  <div>
                    <p>操作成功，具体操作结果请至 【系统查询】-【批量操作查询】 查看</p>
                  </div>
                )
              })
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
